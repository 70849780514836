<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <b-form
          @submit.stop.prevent="formOnSubmit"
          enctype="multipart/form-data"
        >
          <b-card-body title="Ubah Sarana & Prasarana">
            <b-form-group
              id="input-group-name"
              label="Nama Sarana"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model="form.name"
                placeholder="Nama Sarana"
              ></b-form-input>
              <small class="text-danger">{{ error.name }}</small>
            </b-form-group>

            <!-- Input Post Category Id -->
            <b-form-group
              id="input-group-post-category-id"
              label="Jenis Sarana:"
              label-for="input-post-category-id"
            >
              <treeselect
                v-model="form.infrastructure_category_id"
                :multiple="false"
                placeholder="Pilih Jenis Sarana"
                :options="infrastrictureCategory"
              />
              <small class="text-danger">{{
                error.infrastructure_category_id
              }}</small>
            </b-form-group>

            <b-form-group
              id="input-group-image"
              label="Gambar"
              label-for="input-image"
            >
              <b-form-file
                v-model="form.image"
                placeholder="Upload Gambar"
                drop-placeholder="Letakkan file di sini..."
                @change="onFileUpload"
              ></b-form-file>
              <small class="text-danger">{{ error.image }}</small>
            </b-form-group>

            <!-- Content Input -->
            <b-form-group id="input-group-address">
              <label for="input-address">Alamat:</label>
              <b-form-textarea
                id="input-address"
                v-model="form.address"
                placeholder="Deskripsi"
                rows="4"
                max-rows="8"
              ></b-form-textarea>
              <small class="text-danger">{{ error.address }}</small>
            </b-form-group>

            <!-- Button -->
            <b-row class="justify-content-end">
              <b-button type="submit" variant="primary">Simpan</b-button>
              <b-button class="ml-2" variant="secondary" type="reset">
                Reset
              </b-button>
              <b-button class="ml-2" variant="danger" @click="btnCancelOnClick">
                Batal
              </b-button>
            </b-row>
          </b-card-body>
        </b-form>
      </div>
    </div>
  </v-app>
</template>

<style scoped>
.image-input-wrapper {
  width: 200px !important;
  height: 150px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    form: Object,
    route: String,
  },

  data() {
    return {
      // Error
      error: {
        infrastructure_category_id: "",
        address: "",
        name: "",
        image: "",
      },
      // ckeditor
      // editor: DecoupledEditor,
      // editorConfig: {
      //   toolbar: [
      //     "heading",
      //     "|",
      //     "fontFamily",
      //     "fontSize",
      //     "fontColor",
      //     "fontBackgroundColor",
      //     "|",
      //     "bold",
      //     "italic",
      //     "underline",
      //     "strikethrough",
      //     "|",
      //     "indent",
      //     "outdent",
      //     "|",
      //     "alignment",
      //     "|",
      //     "link",
      //     "blockQuote",
      //     "bulletedList",
      //     "numberedList",
      //     "|",
      //     "insertTable",
      //     "|",
      //     "mediaEmbed",
      //     "|",
      //     "undo",
      //     "redo",
      //   ],
      //   alignment: {
      //     options: ["left", "right", "center", "justify"],
      //   },
      //   table: {
      //     toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
      //   },
      // },
      // Other
      infrastrictureCategory: [
       
      ],
      modal2: false,
      uploadedImageUrl: "",
    };
  },

  methods: {
    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },

    onFileUpload(evt) {
      this.uploadedImageUrl = URL.createObjectURL(evt.target.files[0]);
      this.form.image = evt.target.files[0];
    },

    btnCancelOnClick() {
      this.$root.$emit("cancelAddWebsitePost");
      // var self = this;
      // Object.keys(this.error).forEach(function (key, index) {
      //   if (key === "image") {
      //     self.error[key] = "";
      //   } else {
      //     self.error[key] = "";
      //   }
      // });

      // this.$bvModal.hide('modal-news')
    },

    async getInfraCategoryOption() {
      let response = await module.setTreeSelect("infrastructure-category");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.infrastrictureCategory = response.data;
        this.infrastrictureCategory.unshift({
          label: "Pilih Jenis Sarana",
          id: "",
          isDisabled: true,
        });
      }
    },

    async formOnSubmit() {
      this.form._method = "put"
      let formData = new FormData();
      for (const [key, value] of Object.entries(this.form)) {
        if (key === "status") {
          formData.append(key, value ? 1 : 0);
        } else {
          formData.append(key, value);
        }
      }
      let response = await module.submit(formData, this.route);
      console.log("response", response);
      if (response.state == "error") {
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        var self = this;
        Object.keys(this.error).forEach(function (key, index) {
          if (key === "image") {
            self.error[key] = "";
          } else {
            self.error[key] = "";
          }
        });
        this.uploadedImageUrl = "";
        Swal.fire(response.success.title, response.success.message, "success");
        this.$root.$emit("successSaveDataInfra");
      }
    },
  },

  mounted() {
    this.getInfraCategoryOption();
  },
};
</script>

<style>
.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>
