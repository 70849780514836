<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text"><b>Sarana & Prasarana Desa</b></div>
      <b-button squared variant="success" @click="btnAddOnClick"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <Card>
          <template v-slot:body>
            <Table purpose="master" />
          </template>
        </Card>
      </div>
    </div>

    <b-modal id="modal-infrastructure" size="xl" title="" hide-footer>
      <b-row no-gutters>
        <b-col md="12">
          <Form
            :form="form"
            :route="'infrastructure'"
            v-if="FormDetailActv == false && FormEditActv == false"
          />
          <FormEdit
            :form="form"
            :route="'infrastructure/' + form.id"
            v-if="FormEditActv == true"
          />
          <!-- <FormDetail :form="form" :route="'infrastructure/' + form.id" v-if="FormDetailActv == true"/> -->
          <FormDetail :data="detailData" v-if="FormDetailActv == true" />
        </b-col>
      </b-row>
    </b-modal>

    
  </div>
</template>

<script>
import Card from "@/view/content/Card.vue";
import Form from "@/view/components/infrastructure/Add.vue";
import FormEdit from "@/view/components/infrastructure/Edit.vue";
import FormDetail from "@/view/components/infrastructure/Detail.vue";
import Table from "@/view/components/infrastructure/Table.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "website-infrastructure",
  data() {
    return {
      form: {
        infrastructure_category_id: "",
        address: "",
        alamat: "",
        image: "",
        pray_place:0,
      },
      FormEditActv: false,
      // detail
      FormDetailActv: false,
      detailData: "",
    };
  },
  components: {
    Card,
    Table,
    Form,
    FormEdit,
    FormDetail,
  },
  methods: {
    resetFormOnNew() {
      // reset form on new (tambah data)
      var self = this;
      Object.keys(this.form).forEach(function (key, index) {
        if (key === "image") {
          self.form[key] = [];
        } else if (key === "_method") {
          self.form[key] = "post";
        } else {
          self.form[key] = "";
        }
      });
    },

    scrollToElement(options) {
      let el = this.$el.getElementsByClassName("pagination")[0];
      el = el ?? this.$el.getElementsByClassName("table-setting")[0];
      console.log("el", el);
      if (el) {
        el.scrollIntoView(options);
        // reset form on new (tambah data)
        this.resetFormOnNew();
      }
    },

    scrollToTop(options) {
      // window.scrollTo(0, 0);
      let el = this.$el.getElementsByClassName("alert")[0];
      let er = this.$el.getElementsByClassName("alert");
      // el = el ?? this.$el.getElementsByClassName("table-setting")[0];
      console.log("el", el);
      console.log("er", er);
      if (el) {
        el.scrollIntoView(options);
        // reset form on new (tambah data)
        this.resetFormOnNew();
      }
    },

    btnAddOnClick() {
      // this.scrollToElement({ behavior: 'smooth' })
      this.FormDetailActv = false;
      this.FormEditActv = false;
      this.$bvModal.show("modal-infrastructure");
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sarana & Prasarana Desa", route: "" }]);

    // save data success
    this.$root.$on("successSaveDataInfra", () => {
      this.resetFormOnNew();
      this.$root.$emit("updateInfra");
      this.$bvModal.hide("modal-infrastructure");
    });

    this.$root.$on("cancelAddWebsitePost", () => {
      // reset form on new (tambah data)
      // this.scrollToTop({ behavior: "smooth" });
      this.resetFormOnNew();
      this.$bvModal.hide("modal-infrastructure");
    });

    // Detail Web Post
    this.$root.$on("getInfraByIdDetail", (infrastructure) => {
      this.FormDetailActv = true;
      this.FormEditActv = false;
      this.detailData = infrastructure;
      this.$bvModal.show("modal-infrastructure");
    });

    // Detail Web Post
    this.$root.$on("editDataOnClick", (infrastructure) => {
      this.FormDetailActv = false;
      this.FormEditActv = true;
      this.form = infrastructure;
      this.$bvModal.show("modal-infrastructure");
    });
  },
};
</script>
