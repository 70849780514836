var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-content-end mt-n3"},[_c('div',{staticClass:"col-md-3"},[_c('b-input-group',{scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('b-input-group-text',{attrs:{"squared":""}},[_c('b-icon-search')],1)]},proxy:true}])},[_c('b-form-input',{attrs:{"type":"text","placeholder":"Cari Nama"},model:{value:(_vm.filterbyName),callback:function ($$v) {_vm.filterbyName=$$v},expression:"filterbyName"}})],1)],1)]),_c('div',{staticClass:"overflow-auto table-setting"},[_c('b-table',{style:('white-space: nowrap'),attrs:{"striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"responsive":"sm"},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(scope){return _vm._l((scope.fields),function(field){return _c('col',{key:field.key,style:({
            width:
              field.key === 'actions'
                ? _vm.purpose == 'master'
                  ? '20%'
                  : ''
                : '',
          })})})}},{key:"cell(actions)",fn:function(data){return [(_vm.purpose == 'master')?[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-info",attrs:{"size":"sm","title":"Detail"},on:{"click":function($event){return _vm.btnDetailOnClick(data.item.id)}}},[_c('i',{staticClass:"fas fa-eye px-0"})]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-1 btn-success",attrs:{"size":"sm","title":"Edit"},on:{"click":function($event){return _vm.btnEditOnClick(data.item.id)}}},[_c('i',{staticClass:"fas fa-edit px-0"})]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn-danger",attrs:{"size":"sm","title":"Hapus"},on:{"click":function($event){return _vm.deleteData(data.item.id)}}},[_c('i',{staticClass:"fas fa-trash px-0"})])]:_vm._e()]}}])}),_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.items.length != 0)?_c('b-pagination',{staticClass:"mt-4",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage},on:{"page-click":_vm.paginationClick},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }