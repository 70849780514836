<template>
  <v-app>
    <b-card-body title="Detail Sarana & Prasarana">
      <div class="row">
        <div class="col-md-6">
          <img
              class="image"
              :src="data.image"
              style="width: 100%; height: 100%; object-fit: contain"
            />
        </div>
        <div class="col">
          <table class="table mb-3">
            <tr>
              <td><strong>Nama Sarana</strong></td>
              <td>{{ data.name }}</td>
            </tr>
            <tr>
              <td><strong>Jenis Sarana</strong></td>
              <td>{{ data.infrastructure_category_name }}</td>
            </tr>
            <tr>
              <td><strong>Alamat</strong></td>
              <td>{{ data.address }}</td>
            </tr>
          
            
          </table>
          <!-- <button class="btn mx-1 btn-success">
            <i class="far fa-fw fa-edit"></i> Edit
          </button>
          <button class="btn mx-1 btn-danger">
            <i class="far fa-fw fa-trash-alt"></i> Hapus
          </button> -->
        </div>
      </div>
    </b-card-body>
  </v-app>
</template>

<style scoped>
.image-input-wrapper {
  width: 500px !important;
  height: 250px !important;
}

.image-input-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  props: {
    data: Object
  },

  data() {
    return {
    };
  },
};
</script>

